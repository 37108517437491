var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.titulo)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.customers,"item-text":"nombre","item-value":"codigo","label":"Escoge un Productor","clearable":"","rounded":"","solo":"","hide-details":""},model:{value:(_vm.id_customer),callback:function ($$v) {_vm.id_customer=$$v},expression:"id_customer"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.types,"item-text":"nombre","item-value":"codigo","label":"Escoge la clase de Café","clearable":"","rounded":"","solo":"","hide-details":""},model:{value:(_vm.id_coffee),callback:function ($$v) {_vm.id_coffee=$$v},expression:"id_coffee"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.places,"item-text":"name","item-value":"id","label":"Escoge la ubicación","clearable":"","rounded":"","solo":"","hide-details":""},model:{value:(_vm.id_place),callback:function ($$v) {_vm.id_place=$$v},expression:"id_place"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.lotFilterOptions,"label":"Escoge el Usuario","clearable":"","rounded":"","solo":"","hide-details":""},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.dates,"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Filtrar por fecha","prepend-icon":"fa-calendar-alt","readonly":"","filled":"","rounded":"","clearable":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":"","title-date-format":_vm.title,"scrollable":"","max":_vm.today},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-row',{staticStyle:{"flex-wrap":"nowrap"},attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.dates);
                    _vm.updateDateFilter();}}},[_vm._v("Aceptar")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"success","block":""},on:{"click":_vm.getByCustomer}},[_vm._v(" Filtrar "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fas fa-filter ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"loading":_vm.loading3,"disabled":_vm.loading3,"color":"warning","block":""},on:{"click":_vm.getAll}},[_vm._v(" Ver Todo "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fas fa-eye ")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.currentItems,"search":_vm.search,"sort-by":"date","sort-desc":"","loading":_vm.loading,"loading-text":"Consultando datos... Espere por favor"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.date))+" ")]}},{key:"item.precio",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.precio))+" ")]}},{key:"item.cost",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.cost))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"gray--text"},[_c('th',{staticClass:"title",attrs:{"colspan":"8"}},[_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_vm._v(" Total: "+_vm._s(_vm.numberWithCommas((parseFloat(_vm.sumField('cost'))).toFixed(2)))+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_vm._v(" Compra: "+_vm._s(_vm.numberWithCommas((parseFloat(_vm.sumField('total_buy'))).toFixed(2)))+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_vm._v(" Deposito: "+_vm._s(_vm.numberWithCommas((parseFloat(_vm.sumField('total_deposit'))).toFixed(2)))+" ")])],1)],1)])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }